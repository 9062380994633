export const deleteDemoQuestionFromLocalStorage = () => {
  let question_id = [
    "Q1",
    "Q2",
    "Q3",
    "Q223096",
    "Q223101",
    "Q223112",
    "Q223114",
  ];
  for (let i = 0; i < question_id.length; i++) {
    localStorage.removeItem(question_id[i]);
  }
};
